import React, { useState, useEffect, useRef, useCallback } from 'react';

const PomodoroTimer = () => {
  const [timeLeft, setTimeLeft] = useState(25 * 60);
  const [isWorking, setIsWorking] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [workTime, setWorkTime] = useState(25);
  const [restTime, setRestTime] = useState(5);
  const [darkMode, setDarkMode] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const intervalRef = useRef(null);
  const audioContextRef = useRef(null);

  // Function to format the timer display
  const updateTimer = useCallback(() => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  }, [timeLeft]);

  // Function to play a sound when the timer reaches zero
  const playSound = useCallback(() => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (
        window.AudioContext || window.webkitAudioContext
      )();
    }
    const oscillator = audioContextRef.current.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(
      440,
      audioContextRef.current.currentTime
    );
    const gainNode = audioContextRef.current.createGain();
    gainNode.gain.setValueAtTime(0, audioContextRef.current.currentTime);
    gainNode.gain.linearRampToValueAtTime(
      1,
      audioContextRef.current.currentTime + 0.01
    );
    gainNode.gain.linearRampToValueAtTime(
      0,
      audioContextRef.current.currentTime + 0.5
    );
    oscillator.connect(gainNode);
    gainNode.connect(audioContextRef.current.destination);
    oscillator.start();
    oscillator.stop(audioContextRef.current.currentTime + 0.5);
  }, []);

  // Function to switch between work and rest modes
  const switchMode = useCallback(() => {
    setIsWorking((prevIsWorking) => {
      const newIsWorking = !prevIsWorking;
      setTimeLeft(newIsWorking ? workTime * 60 : restTime * 60);
      return newIsWorking;
    });
    setIsPaused(true); // Pause the timer when switching modes
  }, [workTime, restTime]);

  // Function to start the timer
  const startTimer = useCallback(() => {
    if (isPaused) {
      setIsPaused(false);
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 1) {
            return prevTime - 1;
          } else {
            clearInterval(intervalRef.current);
            return 0; // Set to 0 to trigger useEffect
          }
        });
      }, 1000);
    }
  }, [isPaused]);

  // Function to pause the timer
  const pauseTimer = useCallback(() => {
    clearInterval(intervalRef.current);
    setIsPaused(true);
  }, []);

  // Function to reset the timer
  const resetTimer = useCallback(() => {
    pauseTimer();
    setTimeLeft(isWorking ? workTime * 60 : restTime * 60);
  }, [isWorking, workTime, restTime, pauseTimer]);

  // Function to handle manual mode changes
  const handleModeChange = useCallback(
    (e) => {
      const newMode = e.target.value === 'work';
      setIsWorking(newMode);
      setTimeLeft(newMode ? workTime * 60 : restTime * 60);
      pauseTimer(); // Pause the timer when manually changing modes
    },
    [workTime, restTime, pauseTimer]
  );

  // Function to toggle dark mode
  const toggleDarkMode = useCallback(() => {
    setDarkMode((prev) => !prev);
  }, []);

  // Effect to clean up the interval on unmount
  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  // Effect to handle timer reaching zero
  useEffect(() => {
    if (timeLeft === 0) {
      playSound();
      switchMode();
    }
  }, [timeLeft, playSound, switchMode]);

  return (
    <div className={`body ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="container">
        <div className="hamburger-menu">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="hamburger-button"
          >
            ☰
          </button>
          {menuOpen && (
            <div className="menu-content">
              <div className="settings">
                <div>
                  <label htmlFor="modeSelect">Select Mode:</label>
                  <select
                    id="modeSelect"
                    value={isWorking ? 'work' : 'rest'}
                    onChange={handleModeChange}
                  >
                    <option value="work">Work</option>
                    <option value="rest">Rest</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="workTime">Work Time (minutes):</label>
                  <input
                    type="number"
                    id="workTime"
                    value={workTime}
                    min="1"
                    max="60"
                    onChange={(e) => setWorkTime(Number(e.target.value))}
                  />
                </div>
                <div>
                  <label htmlFor="restTime">Rest Time (minutes):</label>
                  <input
                    type="number"
                    id="restTime"
                    value={restTime}
                    min="1"
                    max="30"
                    onChange={(e) => setRestTime(Number(e.target.value))}
                  />
                </div>
                <div className="dark-mode-toggle">
                  <label htmlFor="darkModeToggle">Dark Mode</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      id="darkModeToggle"
                      checked={darkMode}
                      onChange={toggleDarkMode}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="timer-container">
          <h1>Pomodoro Timer</h1>
          <div id="timer">{updateTimer()}</div>
          <div className="mode-indicator">
            {isWorking ? 'Work' : 'Rest'} Mode
          </div>
        </div>
        <div className="button-container">
          <button onClick={startTimer} disabled={!isPaused}>
            Start
          </button>
          <button onClick={pauseTimer} disabled={isPaused}>
            Pause
          </button>
          <button onClick={resetTimer}>Reset</button>
        </div>
      </div>
      <style jsx>{`
        .body {
          font-family: Arial, sans-serif;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.5s ease, color 0.5s ease;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
        }
        .light-mode {
          background-color: #f0f0f0;
          color: #333;
        }
        .dark-mode {
          background-color: #333;
          color: #f0f0f0;
        }
        .container {
          text-align: center;
          padding: 1rem;
          border-radius: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          transition: background-color 0.5s ease, color 0.5s ease;
          position: relative;
          width: fit-content;
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .light-mode .container {
          background-color: white;
        }
        .dark-mode .container {
          background-color: #444;
        }
        h1 {
          margin: 0 0 0.5rem 0;
          font-size: 1.2rem;
        }
        #timer {
          font-size: 2.5rem;
          margin: 0;
          padding: 0;
        }
        .timer-container {
          margin-top: 2rem;
          margin-bottom: 0.5rem;
        }
        .mode-indicator {
          font-size: 0.9rem;
          margin-top: 0.3rem;
        }
        .button-container {
          margin-bottom: 0.5rem;
        }
        button {
          font-size: 0.9rem;
          padding: 0.3rem 0.7rem;
          margin: 0.2rem;
          cursor: pointer;
          background-color: #4caf50;
          color: white;
          border: none;
          border-radius: 5px;
          transition: background-color 0.3s;
        }
        button:hover {
          background-color: #45a049;
        }
        button:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
        input,
        select {
          width: 60px;
          margin: 0.2rem;
          padding: 0.2rem;
          font-size: 0.8rem;
          background-color: inherit;
          color: inherit;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        .hamburger-menu {
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 1000;
        }
        .hamburger-button {
          background: none;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
          color: inherit;
        }
        .menu-content {
          position: absolute;
          top: 100%;
          right: 0;
          background-color: inherit;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 1rem;
          z-index: 1000;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
          margin-bottom: 1rem;
        }
        .light-mode .menu-content {
          background-color: #f9f9f9;
        }
        .dark-mode .menu-content {
          background-color: #333;
        }
        .settings {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 0.8rem;
        }
        .settings > div {
          margin: 0.3rem 0;
        }
        .dark-mode-toggle {
          display: flex;
          align-items: center;
          font-size: 0.8rem;
        }
        .toggle-switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 22px;
          margin-left: 10px;
        }
        .toggle-switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: 0.4s;
          border-radius: 22px;
        }
        .slider:before {
          position: absolute;
          content: '';
          height: 16px;
          width: 16px;
          left: 3px;
          bottom: 3px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
        input:checked + .slider {
          background-color: #2196f3;
        }
        input:checked + .slider:before {
          transform: translateX(18px);
        }
        @media (max-width: 300px) {
          .container {
            padding: 0.5rem;
          }
          h1 {
            font-size: 1rem;
          }
          #timer {
            font-size: 2rem;
          }
          button {
            font-size: 0.8rem;
            padding: 0.2rem 0.5rem;
          }
          .settings,
          .dark-mode-toggle {
            font-size: 0.7rem;
          }
          input,
          select {
            width: 50px;
            font-size: 0.7rem;
          }
        }
      `}</style>
    </div>
  );
};

export default PomodoroTimer;
